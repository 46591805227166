import { sbEditable } from '@storyblok/storyblok-editable'
import Introduction from '../introduction'
import TopNews from '../topnews'
import React from "react"
import TopicList from '../topicList'
 
const Components = {
  'preface': Introduction,
  'news': TopNews,
  'topicList': TopicList
}
 
const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return (<div {...sbEditable(blok)}><Component blok={blok} /></div>)
  }
  return (<p>The component <strong>{blok.component}</strong> has not been created yet.</p>)
}
 
export default DynamicComponent