import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import StoryblokService from "../../utilities/storyblok-service"

import { useSelector, useDispatch } from "react-redux"

import "./styles.scss"
import Button from "../button"

const Introduction = ({ blok }) => {
    const isModalDismissed = useSelector(state => state.dismissModal)
    const dispatch = useDispatch()

    let modalVisible = false
    const [modalClass, setModalClass] = React.useState("visible")

    if (!isModalDismissed) {
        modalVisible = true
    }

    const dismissModal = (event) => {
        setModalClass("")
        dispatch({
            type: "DISMISS_MODAL"
        })
    }

    return (
    modalVisible &&
    <div className={"introduction " + modalClass}>
        <div className="box">
            <div className="close-icon" onClick={dismissModal}></div>
            <div className="text" dangerouslySetInnerHTML={{ __html: StoryblokService.client.richTextResolver.render( blok )}} />
            <div className="partners">
                <a href="https://www.bezreg-muenster.de/de/index.html" target="_blank" rel="noreferrer">
                  <StaticImage 
                    src="../../images/logo.png"
                    width={120}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Bezirksregierung Münster"
                    loading="eager"
                    placeholder="none"
                  />
                </a>
                <a href="https://www.innenstadt-nrw.de/aktuell" target="_blank" rel="noreferrer">
                  <StaticImage 
                    src="../../images/netzwerk-innenstadt-logo.png"
                    width={80}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Netzwerk Innenstadt NRW"
                    loading="eager"
                    placeholder="none"
                  />
                </a>
                <a href="https://www.ils-forschung.de" target="_blank" rel="noreferrer">
                <StaticImage 
                  src="../../images/ils-logo.png"
                  width={118}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="ILS"
                  loading="eager"
                  placeholder="none"
                />
                </a>
                <a href="https://www.ihk-nordwestfalen.de" target="_blank" rel="noreferrer">
                <StaticImage 
                  src="../../images/ihk-logo.png"
                  width={126}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="IHK Nord Westfalen"
                  loading="eager"
                  placeholder="none"
                />
                </a>
            </div>
            <Button to="" type="primary" action={dismissModal} label="Los geht's!" />
        </div>
        <div className="background" onClick={dismissModal}></div>
    </div>)
}

export default Introduction