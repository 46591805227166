import * as React from "react"
import { graphql } from "gatsby"
import useStoryblok from "../lib/storyblok"
import { sbEditable } from "@storyblok/storyblok-editable"

import "../styles/reset.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

import DynamicComponent from "../components/dynamicComponent"
import Introduction from "../components/introduction"

const IndexPage = ({ data, location }) => {
  let story = data.storyblokEntry
  story = useStoryblok(story, location)

  const components = story.content.content.map(blok => {
    return (<DynamicComponent blok={blok} key={blok._uid} />)
  })

return (
  <Layout>
      <div {...sbEditable(story.content)}>
        <Seo title="Innenstadt Toolbox" />
        <Introduction blok={story.content.prefrace} />
        {components}
      </div>
    </Layout>
)
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: {eq: "home"}) {
      content
      name
    }
  }
`