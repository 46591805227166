import * as React from "react"
import { Link } from "gatsby"

import { Carousel } from "react-responsive-carousel"

import { useSelector } from "react-redux"

import DownloadCopy from "../downloadCopy"

import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import 'react-circular-progressbar/dist/styles.css';

import "./styles.scss"

import "react-responsive-carousel/lib/styles/carousel.min.css"

const TopicList = ({ blok }) => {
    const topics = useSelector(state => state.topics)

    let completionRate = 0

    const topicList = {
        topic1: 0,
        topic2: 0,
        topic3: 0,
        topic4: 0,
        topic5: 0,
        topic6: 0,
        topic7: 0,
        topic8: 0,
        topic9: 0,
        topic10: 0,
        topic11: 0,
        topic12: 0,
        topic13: 0
    }

    for (const [topic, data] of Object.entries(topics)) {
        if (topics[topic].finished) {
            completionRate += 7.6923076923
            topicList[topic] = 100
        }
    }

    React.useEffect(() => {
        for (const [topic, data] of Object.entries(topics)) {
            if (topics[topic].finished) {
                completionRate += 7.6923076923
                topicList[topic] = 100
            }
        }
    }, [topics])

    return (
    <>
    <DownloadCopy />
    <div className="topics-list-mobile">
        <Carousel showArrows={true} centerMode={true} centerSlidePercentage={50} infiniteLoop={true} emulateTouch={true} showThumbs={false} showStatus={false} showIndicators={false}>
            <div className="topic1 topic">
                <Link to={`/${blok.topic1.full_slug}/analyse`}>
                    <div className="label">{blok.topic1.name}</div>
                    <CircularProgressbar value={topics.topic1.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
            <div className="topic2 topic">
                <Link to={`/${blok.topic2.full_slug}/analyse`}>
                <div className="label">{blok.topic2.name}</div>
                    <CircularProgressbar value={topics.topic2.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
            <div className="topic3 topic">
                <Link to={`/${blok.topic3.full_slug}/analyse`}>
                <div className="label">{blok.topic3.name}</div>
                    <CircularProgressbar value={topics.topic3.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
            <div className="topic4 topic">
                <Link to={`/${blok.topic4.full_slug}/analyse`}>
                <div className="label">{blok.topic4.name}</div>
                    <CircularProgressbar value={topics.topic4.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
            <div className="topic5 topic">
                <Link to={`/${blok.topic5.full_slug}/analyse`}>
                <div className="label">{blok.topic5.name}</div>
                    <CircularProgressbar value={topics.topic5.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
            <div className="topic6 topic">
                <Link to={`/${blok.topic6.full_slug}/analyse`}>
                <div className="label">{blok.topic6.name}</div>
                    <CircularProgressbar value={topics.topic6.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
            <div className="topic7 topic">
                <Link to={`/${blok.topic7.full_slug}/analyse`}>
                <div className="label">{blok.topic7.name}</div>
                    <CircularProgressbar value={topics.topic7.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
            <div className="topic8 topic">
                <Link to={`/${blok.topic8.full_slug}/analyse`}>
                <div className="label">{blok.topic8.name}</div>
                    <CircularProgressbar value={topics.topic8.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
            <div className="topic9 topic">
                <Link to={`/${blok.topic9.full_slug}/analyse`}>
                <div className="label">{blok.topic9.name}</div>
                    <CircularProgressbar value={topics.topic9.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
            <div className="topic10 topic">
                <Link to={`/${blok.topic10.full_slug}/analyse`}>
                <div className="label">{blok.topic10.name}</div>
                    <CircularProgressbar value={topics.topic10.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
            <div className="topic11 topic">
                <Link to={`/${blok.topic11.full_slug}/analyse`}>
                <div className="label">{blok.topic11.name}</div>
                    <CircularProgressbar value={topics.topic11.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
            <div className="topic12 topic">
                <Link to={`/${blok.topic12.full_slug}/analyse`}>
                <div className="label">{blok.topic12.name}</div>
                    <CircularProgressbar value={topics.topic12.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
            <div className="topic13 topic">
                <Link to={`/${blok.topic13.full_slug}/analyse`}>
                    <div className="label">{blok.topic13.name}</div>
                    <CircularProgressbar value={topics.topic13.completionRate*100} text="" styles={buildStyles({
                        pathColor: "#0091FF",
                        trailColor: "#EEEFF0",
                        textColor: "#000000"
                    })} />
                </Link>
            </div>
        </Carousel>
        <div className="overall-topic">
        <div className="label">Innenstadt der Zukunft</div>
            <CircularProgressbar value={completionRate} text="" styles={buildStyles({
                pathColor: "#0091FF",
                trailColor: "#EEEFF0",
                textColor: "#000000"
            })} />
        </div>
    </div>
    <div className="topics-list-desktop">
        <div className="topic1 topic">
            <Link to={`/${blok.topic1.full_slug}/analyse`}>
                <div className="label">{blok.topic1.name}</div>
                <CircularProgressbar value={topics.topic1.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="topic2 topic">
            <Link to={`/${blok.topic2.full_slug}/analyse`}>
            <div className="label">{blok.topic2.name}</div>
                <CircularProgressbar value={topics.topic2.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="topic3 topic">
            <Link to={`/${blok.topic3.full_slug}/analyse`}>
            <div className="label">{blok.topic3.name}</div>
                <CircularProgressbar value={topics.topic3.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="topic4 topic">
            <Link to={`/${blok.topic4.full_slug}/analyse`}>
            <div className="label">{blok.topic4.name}</div>
                <CircularProgressbar value={topics.topic4.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="topic5 topic">
            <Link to={`/${blok.topic5.full_slug}/analyse`}>
            <div className="label">{blok.topic5.name}</div>
                <CircularProgressbar value={topics.topic5.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="topic6 topic">
            <Link to={`/${blok.topic6.full_slug}/analyse`}>
            <div className="label">{blok.topic6.name}</div>
                <CircularProgressbar value={topics.topic6.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="topic7 topic">
            <Link to={`/${blok.topic7.full_slug}/analyse`}>
            <div className="label">{blok.topic7.name}</div>
                <CircularProgressbar value={topics.topic7.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="topic8 topic">
            <Link to={`/${blok.topic8.full_slug}/analyse`}>
            <div className="label">{blok.topic8.name}</div>
                <CircularProgressbar value={topics.topic8.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="topic9 topic">
            <Link to={`/${blok.topic9.full_slug}/analyse`}>
            <div className="label">{blok.topic9.name}</div>
                <CircularProgressbar value={topics.topic9.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="topic10 topic">
            <Link to={`/${blok.topic10.full_slug}/analyse`}>
            <div className="label">{blok.topic10.name}</div>
                <CircularProgressbar value={topics.topic10.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="topic11 topic">
            <Link to={`/${blok.topic11.full_slug}/analyse`}>
            <div className="label">{blok.topic11.name}</div>
                <CircularProgressbar value={topics.topic11.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="topic12 topic">
            <Link to={`/${blok.topic12.full_slug}/analyse`}>
            <div className="label">{blok.topic12.name}</div>
                <CircularProgressbar value={topics.topic12.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="topic13 topic">
            <Link to={`/${blok.topic13.full_slug}/analyse`}>
                <div className="label">{blok.topic13.name}</div>
                <CircularProgressbar value={topics.topic13.completionRate*100} text="" styles={buildStyles({
                    pathColor: "#0091FF",
                    trailColor: "#EEEFF0",
                    textColor: "#000000"
                })} />
            </Link>
        </div>
        <div className="overall-topic">
        <div className="label">Innenstadt der Zukunft</div>
            <CircularProgressbar value={completionRate} text="" styles={buildStyles({
                pathColor: "#0091FF",
                trailColor: "#EEEFF0",
                textColor: "#000000"
            })} />
        </div>
    </div>
    <div className="tooltip">Klicken Sie auf eines der Themengebiete um mit der Bearbeitung zu starten</div>
    </>)
}

export default TopicList