import * as React from "react"
import { useSelector } from "react-redux"

import axios from "axios"
import moment from "moment"

import "./styles.scss"

const DownloadCopy = () => {
    const topics = useSelector(state => state.topics)
    const [isLoading, setIsLoading] = React.useState(false)

    const downloadPDF = async () => {
        try {
            if (!isLoading) {
                setIsLoading(true)
                const answers = {}
                for (const [key, value] of Object.entries(topics)) {
                    Object.assign(answers, value.answers)
                }
                const file = await axios.post("https://api.city-toolbox.conducolabs.dev/pdf", {
                    answers: answers
                }, {
                    responseType: "blob"
                })
                const url = window.URL.createObjectURL(new Blob([file.data]));
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${ moment().format('YYYY-MM-DD-HH:mm')}_innenstadt-toolbox.pdf`)
                document.body.appendChild(link)
                link.click()
                setIsLoading(false)
            }
        } catch(error) {

        }
    }
    return (
        <div className="download-copy button-wrapper">
            <div className="button primary" onClick={() => { downloadPDF()}}>
                {isLoading &&
                <>
                    Generiere Kopie...
                </>
                }
                {!isLoading &&
                <>
                    Kopie herunterladen
                </>
                }
            </div>
        </div>
    )
}

export default DownloadCopy